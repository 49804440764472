import React from "react";
import { graphql } from "gatsby";
import { Helmet } from "react-helmet";
import Layout from "../Layout";
import { layoutOptions } from "../components/Content/RichText";
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

const LandingPage = ({ location, data }) => {

    const {
        slug,
        content,
        // form,
        metaImage,
        metaDescription,
        pageTitle
    } = data.contentfulLandingPage;

    const richText = documentToReactComponents(content.json, layoutOptions)
    const ogImage = `https:${metaImage.file.url}`;
    const metaUrl = `https://michaellisboa.com/${slug}/`

    return (
        <Layout location={location}>
            <Helmet>
                <title>{pageTitle}</title>
                <meta name="url" content={metaUrl} />
                <meta name="description" content={metaDescription} />
                <meta name="image" content={ogImage} />

                <meta property="og:url" content={metaUrl} />
                <meta property="og:title" content={pageTitle} />
                <meta property="og:description" content={metaDescription} />
                <meta property="og:image" content={ogImage} />
                <meta property="og:type" content="article" />

                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:site" content="@Michael__Lisboa" />
                <meta name="twitter:title" content={pageTitle} />
                <meta name="twitter:description" content={metaDescription} />
                <meta name="twitter:image" content={ogImage} />

                <link rel="canonical" href={metaUrl} />
            </Helmet>
            <section className="uk-section uk-section-large">
                {richText}
            </section>
            {/* {form?.length ?
                <Contact form={form[0]?.formObject} />
                :
                <Footer className={`uk-background-secondary uk-light`} />
            } */}
        </Layout>
    )
}
export default LandingPage;

export const richTextQuery = graphql`
    query richTextQuery($slug: String) {
        contentfulLandingPage(slug: {
            eq: $slug
        }) {
            slug
            pageTitle
            metaImage {
                file {
                    url
                }
            }
            metaDescription
            content {
                json
            }
            form {
                formObject {
                    formObject
                }
            }
        }
    }`
